import myVideo from "./images/logo.jpg";
import blocktonesLogo from "./images/blocktones.png";
import { Input } from "./components/Input/Input";
import { useState } from "react";
import { CCallout } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";
import "./App.css";
type CheckResponse = {
    message: string;
    approved: boolean;
};
function App() {
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<CheckResponse>({
        message: "",
        approved: false,
    });
    const { message, approved } = response;
    const checkWallet = (wallet: string) => {
        setLoading(true);
        fetch(`.netlify/functions/checkWallet?wallet=${wallet}`)
            .then((res) => res.json())
            .then((text) => setResponse(text))
            .then(() => setLoading(false));
    };
    return (
        <div className="App">
            <div className="container">
                <div className="mintRow">
                    <div className="rowItem">
                        <div className="player-wrapper">
                            <img
                                alt="Blocktones"
                                className="spinningLogo"
                                src={myVideo}
                            />
                        </div>
                    </div>
                    <div className="rowItem">
                        <img
                            className="blocktonesLogo"
                            alt="Blocktones"
                            src={blocktonesLogo}
                        />
                        <p>Check your wallet</p>
                        <Input
                            value={address}
                            onChange={(e: any) => setAddress(e.target.value)}
                        />
                        {message && (
                            <CCallout color={approved ? "success" : "danger"}>
                                {message}
                            </CCallout>
                        )}
                        <button
                            className="button"
                            onClick={() => checkWallet(address)}
                        >
                            {loading ? "Loading" : "Check Wallet"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
