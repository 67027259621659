import React from "react";
// import { render } from "react-dom";
// import { TransitionMotion, spring } from "react-motion";
import "./style.css";

export class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: (props.locked && props.active) || false,
            error: props.error || "",
            label: props.label || "Wallet Address",
        };
    }

    // changeValue(event) {
    //     const value = event.target.value;
    //     this.setState({ value, error: "" });
    // }

    render() {
        const { active, error, label } = this.state;
        const { locked, value, onChange } = this.props;
        const fieldClassName = `field ${
            (locked ? active : active || value) && "active"
        } ${locked && !active && "locked"}`;

        return (
            <div className={fieldClassName}>
                <input
                    id={1}
                    type="text"
                    value={value}
                    placeholder={label}
                    onChange={onChange}
                    onFocus={() => !locked && this.setState({ active: true })}
                    onBlur={() => !locked && this.setState({ active: false })}
                />
                <label htmlFor={1} className={error && "error"}>
                    {error || label}
                </label>
            </div>
        );
    }
}
